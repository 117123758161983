
import NavBar from './componets/navBar';
import HomePage from './componets/HomePage';
import AboutMe from './componets/AboutMe';
import Experience from './componets/Experience';
import ContactMe from './componets/ContactMe';
import Project from "./componets/projects"

function App() {
  return (
    <div className='bg-gray-100'>
      <NavBar />
      <div className='m-auto md:w-[95%] xl:w-[85%]  w-full'>
        < HomePage />
        <AboutMe />
        <Experience />
        <Project />
        <ContactMe />
      </div>
    </div>
  );
}

export default App;
