import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  sent: false,
  error: null,
};

export const contactSlice = createSlice({
  name: 'contact',
  initialState,
  reducers: {
    sendLoading:(state, action)=>{
      return {...state, isLoading:true, error: null}
    }, 
    sendSucces:(state, action)=>{
      return {...state, isLoading:false, sent: action.payload}
    },
    sendFailure:(state, action)=>{
      return {...state, isLoading:false, error: action.payload}
    }

  },
});

export const { sendFailure,sendLoading,sendSucces } = contactSlice.actions;

