import { IconLink } from "@tabler/icons-react";
import React from "react";

export default function index({
  title,
  link,
  position,
  startDate,
  endDate,
  Achievements,
  jobType
}) {
  const viewAchievements = Achievements.map((achievement) => (
    <li key={achievement.id} className="text-secondary ">
      {achievement.description}
    </li>
  ));
  return (
    <div className="w-[95%] md:w-[100%] text-primary border-y py-2 md:mx-2 xl:mx-0 mx-4">
      <div class="flex flex-col gap-1 w-[80%] md:w-full">
          <p class=" flex items-center  gap-2 ">
            <a
              href={link}
              target="_blank"
              rel="noreferrer"
              class=" text-lg font-semibold flex items-center gap-1 hover:text-purp   py-1"
            >
            <IconLink size={18} />
              <span>{title}</span>
            </a>
          </p>
        <div class="text-sm font-bold flex items-center gap-x-2">
          <span>{position}</span>
          <div className="h-1.5 w-1.5 bg-gray-400 rounded-full"></div>
          <span className="text-xs font-semibold text-gray-700">{jobType}</span>
           </div>
        <span class="text-xs text-gray-500 ">
          {startDate} - {endDate}
        </span>
      </div>
      <ul className=" px-3  md:w-full mt-3 flex flex-col items-start gap-2 text-gray-400 text-sm list-disc">
        {viewAchievements}
      </ul>
    </div>
  );
}
