import { Helmet } from "react-helmet";

// 
export default function HomePage() {
  return (
    <>
    <Helmet>
        <title>ntare.dev</title>
    </Helmet>
      <div className="w-[84%] lg:w-full m-auto max-w-screen-2xl lg:min-h-[700px] min-h-[550px] flex items-center justify-center pt-10" id="Home">
        <div className=" flex flex-col justify-center items-center md:mb-0 animate-appear mt-32 ">
          <h3 className=" font-bold font-bree  text-primary  pt-2 text-sm lg:text-xl ">
            Hello 👋🏾 there! Name is
          </h3>
          <h3 className="xl:text-7xl lg:text-6xl font-bree pt-3 text-[28px] font-bold text-secondary">
            IGIRINEZA Cedrick Tresor
          </h3>
          <h5 className="text-purp text-3xl  font-black pt-3 base:pt-0 sm:pt-0 lg:text-5xl xl:text-7xl">
            a software engineer
          </h5>
          <p className="text-center  text-base text-secondary lg:w-[45%] w-full mt-10">
            "I am a passionate software engineer who is constantly driven to
            innovate and create meaningful solutions through code."
          </p>
        </div>
      </div>
    </>
  );
}
