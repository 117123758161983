import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: null,
  skills: [],
};

export const skillSlice = createSlice({
  name: "Skills",
  initialState,
  reducers: {
    getSkillsLoading: (state, action) => {
      return { ...state, loading: true, error: null };
    },
    getSkillsSuccess: (state, action) => {
      return { ...state, loading: false, skills: action.payload };
    },
    getSkillsFailure: (state, action) => {
      return { ...state, loading: false, error: action.payload };
    },
  },
});

export const { getSkillsFailure, getSkillsLoading, getSkillsSuccess } =
  skillSlice.actions;
