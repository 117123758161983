import { Link } from "react-router-dom";
import { Helmet} from "react-helmet"

function NotFound() {

  const reRoute= ()=>{
    setTimeout(()=>{
      window.location.href="/"
    },5000)
  }

  return (
    <>
    <Helmet>
        <title className="font-black">page not found</title>
    </Helmet>
    <div className="m-auto w-6/12 pt-20" onLoad={reRoute}>
      <p className="text-center font-alfa text-3xl pt-5">
        sorry this page is not found
      </p>
      <p className="text-center pt-5 font-exo text-purp text-xl"><Link to="/"><span className="hover:underline">Go to home page</span></Link></p>
    </div>
    </>
  );
}
export default NotFound;
