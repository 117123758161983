import { useState } from "react";

export default function NavBar() {
  const [menu, setMenu] = useState(true);
  const [closeMenu, setCloseMenu] = useState(false);
  function handleChanges() {
    setMenu(false);
    setCloseMenu(true);
  }
  function handleClose() {
    setMenu(true);
    setCloseMenu(false);
  }
  return (
    <div className="flex justify-between lg:px-5  py-2 items-center shadow-xl fixed top-0 left-0 w-screen  z-10 backdrop-blur-[8px]">
      <div className="logo">
        <h1 className="text-2xl pl-4 font-lobster cursor-pointer base:text-lg">
          Ntare
        </h1>
      </div>
      <nav className="pb-2 md:block hidden">
        <ul className="flex justify-between pr-6 items-center list">
          <a className="navList" href="#Home">
            <li>Home</li>
          </a>
          <a className="navList" href="#About">
            <li>About Me</li>
          </a>
          <a className="navList" href="#Experience">
            <li>Experience</li>
          </a>
          <a className="navList" href="#projects">
            <li>Projects</li>
          </a>
          <a className="navList" href="#Contact">
            <li>Contact Me</li>
          </a>
          <a
            href="/resume"
            rel="noreferrer"
            target="_blank"
            className=" bg-purp text-white  border-none rounded-md px-4 py-0.5 mr-2 ml-6 font-bold"
          >
            Resume
          </a>
        </ul>
      </nav>
      <div className="md:hidden block px-3 relative">
        {menu && (
          <i
            className={`fa-solid fa-bars-staggered`}
            onClick={handleChanges}
          ></i>
        )}
        {closeMenu && (
          <i className={`fa-solid fa-xmark`} onClick={handleClose}></i>
        )}
        {closeMenu && (
          <nav className="absolute bg-gray-200 w-44 top-12 right-3  px-4 pb-4 shadow-xl rounded-xl ">
            <ul className="list text-center flex flex-col mt-4 md:hidden gap-2 " onClick={handleClose}>
              <a className="navList" href="#Home">
                <li>Home</li>
              </a>
              <a className="navList" href="#About">
                <li>About Me</li>
              </a>
              <a className="navList" href="#Services">
                <li>Services</li>
              </a>
              <a className="navList" href="#projects">
                <li>Projects</li>
              </a>
              <a className="navList" href="#Contact">
                <li>Contact Me</li>
              </a>
              <a
                href="/resume"
                rel="noreferrer"
                target="_blank"
                className=" bg-purp text-white  border-none rounded-md px-4 py-1 mt-2 font-bold"
              >
                Resume
              </a>
            </ul>
          </nav>
        )}
      </div>
    </div>
  );
}
