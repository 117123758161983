import thegreenprotector from "../../images/the_green_protector.png";
import PersonalWebsite from "../../images/PersonalWebsite.png";
import Ecommerce from "../../images/E-commerce.png";
import Ironji from "../../images/ironji.png";
import HealthConnectApp from "../../images/Health_connect_app.png";
import wallet1 from "../../images/wallet1.png";
import Guezshow from "../../images/GuezShow.png";
import sinc from "../../images/Sinc.png";

export const projectData = [
  {
    id: 1,
    projectName: "Health Connect App",
    shortDescription:
      "Transform UK care homes with Healthconnect: AI-driven platform. Monitors drug fridge temps, ensures alerts, optimizing care and operations.",
    technologies: [
      { id: 1, name: "TailwindCSS" },
      { id: 2, name: "NextJS" },
      { id: 3, name: "GraphQl" },
      { id: 4, name: "TRPC" },
      { id: 5, name: "Typescript" },
      { id: 6, name: "PostrgreSQL" },
    ],
    image: HealthConnectApp,
  },
  {
    id: 12,
    projectName: "Sinc.today",
    shortDescription:
      "Sinc: Your one-stop platform to host events and sell tickets effortlessly! Streamline planning, manage guests, and coordinate details seamlessly for hassle-free event fun.",
    technologies: [
      { id: 1, name: "NextJs" },
      { id: 2, name: "Typescript" },
      { id: 3, name: "PostgreSQL" },
      { id: 4, name: "tailwindCSS" },
    ],
    image: sinc,
  },
  {
    id: 11,
    projectName: "Guezz show",
    shortDescription:
      "Elevate your visuals with our expertise! We specialize in crafting stunning 3D animations for film posters, social media, and websites. Transform your ideas into captivating reality.",
    technologies: [
      { id: 1, name: "Reactjs" },
      { id: 2, name: "Typescript" },
      { id: 3, name: "Threejs" },
    ],

    image: Guezshow,
  },
  {
    id: 4,
    projectName: "Ironji",
    shortDescription:
      "Ironji provides a user-friendly platform for customers to order and receive products or facilitate seamless transportation for goods.",
    technologies: [
      { id: 1, name: "nextjs" },
      { id: 3, name: "Google Maps" },
      { id: 4, name: "NodeJS" },
      { id: 5, name: "PostgreSQL" },
      { id: 6, name: "Typescript" },
    ],
    image: Ironji,
  },
  {
    id: 2,
    projectName: "Personal Portfolio",
    shortDescription:
      "This my Personal portfolio which highlights my personal work and how you can reach me and where i share my experience as a full-stack software engineer.",
    technologies: [
      { id: 1, name: "ReactJs" },
      { id: 2, name: "TailwindCSS" },
      { id: 3, name: "Framer motion" },
      { id: 3, name: "Typescript" },
    ],
    image: PersonalWebsite,
  },

  {
    id: 3,
    projectName: "E-commerce website",
    shortDescription:
      "An e-commerce app  that offers a seamless shopping experience, enabling users to browse, select, and purchase products online with ease.",
    technologies: [
      {
        id: 1,
        name: "ReactJs",
      },
      { id: 2, name: "tailwindCSS" },
      { id: 3, name: "NodeJS" },
      { id: 5, name: "PostgreSQL" },
      { id: 2, name: "Javascript" },
    ],
    image: Ecommerce,
  },

  {
    id: 5,
    projectName: "The green protector",
    shortDescription:
      "Created a website for an environment protectors. Highlighting their initiatives through a user-friendly design, the site encourages global participation in preserving our planet.",
    technologies: [{ id: 1, name: "WordPress" }],
    image: thegreenprotector,
  },
  {
    id: 10,
    projectName: "Wallet app",
    shortDescription:
      "Wallet App that you can use to track your daily transaction and that can help you in budget planning for any given time",
    technologies: [
      { id: 1, name: "NextJs" },
      { id: 2, name: "Typescript" },
      { id: 3, name: "NextAuth" },
      { id: 4, name: "PrismaORM" },
      { id: 5, name: "PostgreSQL" },
      { id: 6, name: "tailwindCSS" },
    ],
    image: wallet1,
  },
];
