import resume from "../Resume/[Resume] Cedrick Tresor IGIRINEZA.pdf"
import { Helmet } from "react-helmet"
export default function Resume(){
    return (
        <>
        <Helmet>
            <title>[Resume] Cedrick Tresor</title>
        </Helmet>
        <div className="h-screen bg-white">
            <embed src={resume} className="w-screen lg:h-screen h-4/6 " type="application/pdf" />
        </div>
        </>
    )
}