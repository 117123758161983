import React from "react";

export default function ProjectCard({
  title,
  image,
  description,
  technologies,
}) {
  return (
    <div className="border border-gray-600 rounded-md overflow-hidden pb-2 max-w-[27rem]">
      <img src={image} alt="" className="h-[200px] w-[100%] " />
      <div className="px-2 pt-2 pb-4  bg-gray-200 min-h-full">
        <h1 className="font-bold text-xl text-gray-800 ">{title}</h1>
        <p className="text-sm text-gray-600 first-letter:uppercase mt-2">
          {description}
        </p>
        <div className="mt-3  flex items-center  flex-wrap">
          {technologies.map((tech) => (
            <div
              key={tech.id}
              className="border px-2 py-1 m-1 border-gray-500 text-[10px] rounded-md"
            >
              {tech.name}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
