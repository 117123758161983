export const ExperienceArray = [
  {
    id: 4,
    companyName: "Sinc",
    startDate: "August 2023",
    endPeriod: "present",
    link: "https://sinc.today/",
    position: "Front-end developer",
    jobType: "Part-time",
    Achievement: [
      {
        id: 1,
        description:
          "Craft intuitive and visually appealing user interfaces, prioritizing user experience to enhance overall satisfaction and engagement.",
      },
      {
        id: 2,
        description:
          "Actively collaborate with cross-functional teams, incorporating feedback to ensure the seamless integration of frontend components within projects.",
      },
      {
        id: 3,
        description:
          "Apply analytical and problem-solving skills to troubleshoot and optimize frontend performance, ensuring a smooth and efficient user interface",
      },
      {
        id: 4,
        description:
          "Implement responsive design principles to ensure optimal user experiences across various devices and screen sizes, enhancing accessibility and inclusivity.",
      },
    ],
  },
  {
    id: 1,
    companyName: "Seven X Ltd",
    startDate: "May 2023",
    endPeriod: "Present",
    link: "https://www.sevenxhq.com/",
    position: "Full-stack developer",
    jobType: "Full-time",
    Achievement: [
      {
        id: 1,
        description:
          "Developed the front-end of the web application using Next.js, a React framework, to ensure efficient rendering and optimized performance.",
      },
      {
        id: 2,
        description:
          "Utilized Tailwind CSS to create responsive and visually appealing user interfaces, leveraging its utility-first approach for efficient styling and customization.",
      },
      {
        id: 3,
        description:
          "Collaborated with the design team to translate UI/UX wireframes into functional front-end components, ensuring accurate implementation of design specifications.",
      },
      {
        id: 4,
        description:
          "Integrated GraphQL and Hasura into the backend architecture, enabling efficient data querying and manipulation. Leveraged the power of GraphQL's declarative nature and Hasura's real-time capabilities to optimize data fetching and updates, resulting in a seamless and responsive user experience",
      },
    ],
  },

  {
    id: 2,
    companyName: "Andela Rwanda",
    startDate: "september 2022",
    endPeriod: "september 2023",
    link: "https://andela.com/",
    position: "Full Stack developer",
    jobType: "Full-time",
    Achievement: [
      {
        id: 1,
        description:
          "Implemented a secure user authentication and authorization system to ensure only authorized users can access the website's features and resources using nodejs",
      },
      {
        id: 2,
        description:
          "Created an intuitive product search and filtering system to help customers easily find specific products based on their preferences.",
      },
      {
        id: 3,
        description:
          "Created responsive front-end layouts using ReactJS and Tailwind CSS ensuring an engaging and user-friendly interface that adapts seamlessly to different devices and screen sizes.",
      },
      {
        id: 4,
        description:
          "Implemented a two-factor authentication system for user login, enhancing security by requiring an additional verification step. Integrated Time-based One-Time Password (TOTP) functionality, generating unique codes and securely delivering them to users via email for seamless authentication",
      },
    ],
  },
  // {
  //   id: 3,
  //   companyName: "Ironji",
  //   startDate: "September 2021",
  //   endPeriod: "October 2022",
  //   link: "https://ironji.com/",
  //   position: "Full Stack developer",
  //   jobType: "Full-time",
  //   Achievement: [
  //     {
  //       id: 1,
  //       description:
  //         " As a full-stack developer,Leveraged cutting-edge technologies, including ReactJS, Next.js, and Tailwind CSS, to create an intuitive and responsive user interface for an all-in-one logistics and supply chain management application. Employed front-end libraries such as Material UI, Framer Motion, Headless UI, and Ant Design to enhance the user experience.",
  //     },
  //     {
  //       id: 2,
  //       description:
  //         "Spearheaded back-end development using Node.js and Express to construct a robust server infrastructure that powers a comprehensive logistics and order tracking system. Proficiently managed RESTful APIs to ensure seamless communication between the front-end and back-end components.",
  //     },
  //     {
  //       id: 3,
  //       description:
  //         "My role also involved mastery in database management, primarily utilizing PostgreSQL for our data needs. This encompassed tasks like database design, data manipulation, and data integrity maintenance, ultimately contributing to efficient data handling within the organization.",
  //     },
  //   ],
  // },
];
