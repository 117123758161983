import React from "react";
import ProjectCard from "./ProjectCard";
import { projectData } from "./projectData";

export default function index() {
  return (
    <div id="projects" className="pt-[52px]">
      <h1 className="header sm:text-4xl">Projects</h1>
      <p className="text-center text-gray-700 text-sm md:text-base  font-medium my-2">here is a glimpse of some of the Projects i've worked on:</p>
      <div className="grid grid-cols-1  md:grid-cols-2 xl:grid-cols-4 gap-6  mt-10 mx-4 my-4 md:my-0 md:mx-2 xl:mx-0 xl:mt-10">
        {projectData.map((project) => (
          <ProjectCard
            description={project.shortDescription}
            image={project.image}
            technologies={project.technologies}
            key={project.id}
            title={project.projectName}
          />
        ))}
      </div>
    </div>
  );
}
