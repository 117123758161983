/* eslint-disable eqeqeq */
/* eslint-disable no-restricted-globals */

import { IconBrandGithub, IconBrandLinkedin, IconBuilding, IconHome, IconMail, IconMap2, IconPhone } from "@tabler/icons-react";
export default function ContactMe() {
  return (
    <>
      <div className="pt-24 pb-10 base:py-12 lg:py-10" id="Contact">
        <h1 className="header sm:text-4xl">Contact Me</h1>
        <div className="flex flex-col sm:flex-row sm:items-start px-5 sm:px-2 sm:justify-around  pt-10">
          <div>
            <h1 className="text-secondary font-bold text-xl py-3">
              Contact Address
            </h1>
            <div>
            <div className="p-1  text-secondary flex items-center gap-1.5">
                <IconMap2 className="text-purp h-4 w-4" />
                <span>Rwanda, Africa</span>
              </div>
              <div className="p-1  text-secondary flex items-center gap-1.5">
                <IconBuilding className="text-purp h-4 w-4" />
                <span>Gasabo, Kigali City</span>
              </div>
              <div className="p-1  text-secondary flex items-center gap-1.5">
                <IconHome className="text-purp h-4 w-4" />
                <span>KG 654 Street </span>
              </div>
            </div>
          </div>
          <div>
            <h1 className="text-secondary font-bold text-xl py-3">
              Contack Details
            </h1>
            <div>
            <div className="text-secondary flex items-center gap-1.5">
                <p className="flex items-center gap-1">
                  <IconPhone className="h-4 w-4 text-purp " />
                  <span>Phone:</span>
                </p>
                <span className="font-bold text-sm">
                  +250 788 219 101
                </span>
              </div>
              <div className="text-secondary flex items-center gap-1.5">
                <p className="flex items-center gap-1">
                  <IconMail className="h-4 w-4 text-purp " />
                  <span>Email:</span>
                </p>
                <a className="font-bold text-sm" href="mailto:ntarecedrick250@gmail.com" rel="noreferrer" target="_blank">
                  ntarecedrick250@gmail.com
                </a>
              </div>
              <div className="text-secondary flex items-center gap-1.5">
                <p className="flex items-center gap-1">
                  <IconBrandLinkedin className="h-4 w-4 text-purp " />
                  <span>LinkedIn:</span>
                </p>
                <a className="font-bold text-sm" href="https://www.linkedin.com/in/cedrick-tresor-igirineza-76220024a/" rel="noreferrer" target="_blank">
                  Cedrick Tresor IGIRINEZA
                </a>
              </div>
              <div className="text-secondary flex items-center gap-1.5">
                <p className="flex items-center gap-1">
                  <IconBrandGithub className="h-4 w-4 text-purp " />
                  <span>Github:</span>
                </p>
                <a className="font-bold text-sm" href="https://github.com/Ntarecedrick" rel="noreferrer" target="_blank">
                  Cedrick Tresor IGIRINEZA
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
