import ExperienceCard from "./ExperienceCard";
import { ExperienceArray } from "../data";

export default function Services() {
  return (
    <div className=" pt-[52px] pb-10 " id="Experience">
      <h1 className="header">Experience</h1>
      <div className=" pt-5">
        <div className="flex flex-col">
          {ExperienceArray.map((experience) => (
            <ExperienceCard
              Achievements={experience.Achievement}
              endDate={experience.endPeriod}
              title={experience.companyName}
              key={experience.id}
              position={experience.position}
              link={experience.link}
              startDate={experience.startDate}
              jobType={experience.jobType}
            />
          ))}
          <a
            href="/resume"
            rel="noreferrer"
            target="_blank"
            className="font-bold text-gray-800 mt-2 mx-4 md:mx-2  "
          >
            <span className="decoration-2 hover:text-purp underline underline-offset-4 ">view full Resume</span>
          </a>
        </div>
      </div>
    </div>
  );
}
