/* eslint-disable no-restricted-globals */
import {
  IconBrandGraphql,
  IconBrandNextjs,
  IconBrandNodejs,
  IconBrandReact,
  IconBrandTailwind,
  IconBrandThreejs,
  IconBrandTypescript,
} from "@tabler/icons-react";

export default function AboutMe() {
  return (
    <>
      <div className="w-full pt-20 pb-8 mt-10 base:mt-[450px]" id="About">
        <h1 className="header">About Me</h1>
        <div className="lg:grid grid-cols-3 gap-0 items-start px-22 pt-12 block ">
          <div className="w-full h-full col-span-1 px-5 md:px-3  lg:px-5">
            <img
              src="https://res.cloudinary.com/dmyztchh9/image/upload/v1680981634/ntare-website/ntare_wcluwt.jpg"
              className="w-full h-full object-fit md:object-top rounded-2xl"
              alt=""
            />
          </div>
          <div className="col-span-2 base:w-full pl-5 pt-4">
            <p className="font-sourceSerif text-sm m-auto  text-secondary">
              I am an experienced Fullstack developer specializing in web
              application development. My focus is on producing clean, robust,
              and thoroughly tested code for every project. With a broad skill
              set covering various tools, languages, and technologies, I am
              well-equipped to build modern web applications. My commitment to
              quality and attention to detail sets me apart in delivering
              successful projects. Moreover, my dedication to staying current
              with industry trends and my ability to collaborate effectively
              with diverse teams make me a valuable asset in delivering
              exceptional web solutions. I approach each project with a deep
              sense of dedication, ensuring that every line of code contributes
              to a seamless and efficient user experience. My goal is to create
              web applications that not only meet but exceed your expectations.
            </p>
            <div className="mt-4  text-secondary ">
              <h1 className="font-bold text-xl">Checkout some of my skills</h1>
              <div className="grid grid-cols-2 md:grid-cols-3 gap-3 items-start justify-around mt-3">
              <div className="flex items-center gap-3">
                  <IconBrandTypescript size={50} stroke={1} className="text-[#3178c6]" />
                  <p className="font-medium  text-gray-800"> Typescript</p>
                </div>
                <div className="flex items-center gap-3">
                  <IconBrandReact size={50} stroke={1} className="text-[#169fca]" />
                  <p className="font-medium  text-gray-800"> ReactJS</p>
                </div>
                <div className="flex items-center gap-3">
                  <IconBrandNextjs size={50} stroke={1} className="text-gray-800" />
                  <p className="font-medium  text-gray-800"> NextJS</p>
                </div>
                <div className="flex items-center gap-3">
                  <IconBrandTailwind size={50} stroke={1} className="text-[#39bef8] fill-[#39bef8]" />
                  <p className="font-medium  text-gray-800"> TailwindCSS</p>
                </div>
                <div className="flex items-center gap-3">
                  <IconBrandThreejs size={50} stroke={1} className="text-gray-800" />
                  <p className="font-medium  text-gray-800"> Threejs</p>
                </div>
                <div className="flex items-center gap-3">
                  <IconBrandNodejs size={50} stroke={1} className="text-[#60a04e]" />
                  <p className="font-medium  text-gray-800"> NodeJS</p>
                </div>
                <div className="flex items-center gap-3">
                  <IconBrandGraphql size={50} stroke={1} className="text-[#f7009b]" />
                  <p className="font-medium  text-gray-800">GraphQL</p>
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
